<template>
  <div>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
      <template v-else>
        <b-row>
          <b-col>
            <b-card-actions
              :title="$t('learning_path.filters')"
              action-collapse
              collapsed
            >

            <!-- 

                      'user_id':  this.,
        'group': null,
        'nee': null,
        'age_range': null,
        'searchword': '',
        'start_date':  new Date(this.startDate).getTime(),
        'end_date': new Date(this.endDate).getTime(),


             -->
              <filters
                ref="filter"
                :isReport="true"
                :user_id="$store.getters['user/getUserData'].id"
                :start_date="new Date(startDate).getTime()"
                :end_date="new Date(endDate).getTime()"
                context="user-dreamers"
                :groups-options="groupsOptions"
                :nee="nee"
                :age-range-options="age_range_options"
                @set-data="setData"
              /> 
            </b-card-actions>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <b-form-checkbox
              v-model="allSelected"
              @change="manageSelectAll()"
              inline
            >
              {{ $t('table.select_all')}}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="3"
            md="6"
            v-for="dreamer in dreamers"
            v-bind:key="dreamer.id"
          >

            <avatar-card 
              :item="dreamer"
              :dropdown_options="[]"
              context="dreamer"
              :isReport="true"
              :selected_items="selected_dreamers"
              @item-selected="itemSelected"

              />
        
          </b-col>
        </b-row>
      </template>
   
  </div>
</template>

<script>
import AvatarCard from '@/views/sl-components/AvatarCard.vue'
import Filters from '@/views/sl-components/Filters.vue'

import {searchDreamers} from '@/api/routes'
import { getDreamers } from '@/api/reports'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import {
  BRow, BCol, BSpinner, BFormCheckbox, BCard,
} from 'bootstrap-vue'

export default {
  components:{
    BRow, BCol, BSpinner, BFormCheckbox, BCard, BCardActions,

    AvatarCard,
    Filters
  },
  props:{
    selected_dreamers:{
      type: Array,
      required: true,
      default() {
          return []
      }
    },
    endDate:{
      type: String,
      required: true
    },
    startDate:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      dreamers: [],
      groups:[],
      nee: [],

      allSelected: false,
    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    groupsOptions(){
      let newGroupsOptions = []
      this.groups.forEach(x => {
        let can_select = true;
        newGroupsOptions.push(
            { 'value': x.id, 'label': x.name, 'can_select': can_select }
        ) 
      });
      return newGroupsOptions;
    },
    age_range_options(){
      return [
        { 'value': [3,6], 'label': '3-6', 'can_select': true },
        { 'value': [6,7], 'label': '6-7', 'can_select': true },
        { 'value': [7,8], 'label': '7-8', 'can_select': true },
        { 'value': [8,9], 'label': '8-9', 'can_select': true },
        { 'value': [9,10], 'label': '9-10', 'can_select': true },
        { 'value': [10,11], 'label': '10-11', 'can_select': true },
        { 'value': [11,12], 'label': '11-12', 'can_select': true },
      ]
    },
  },
  methods:{
    setData(data){
      // console.log("---------------------------")
      // console.log("set data", data)
        if(data.dreamers.length > 40000){
            this.dreamers = []
            this.loading = false 
        } else {
            this.loading = false 
            this.dreamers = data.dreamers;
        }
        this.allSelected = false
        this.manageSelectAll()
        // console.log(this.dreamers)
    },
    manageSelectAll(){
      if(this.allSelected){
        //let sel_dreamers = this.dreamers.map( i => i.id)
        let sel_dreamers = this.dreamers
        this.$emit('dreamers-selected', sel_dreamers)
      } else {
        this.$emit('dreamers-selected', [])

      }
    },
    itemSelected(item){
      let sel_dreamers_ids = this.selected_dreamers.map(x => x.id)
      var index = sel_dreamers_ids.indexOf(item.id);
      let sel_dreamers = [...this.selected_dreamers]
      if (index !== -1) {
        sel_dreamers.splice(index, 1);
      } else {
        //sel_dreamers.push(item.id)
        sel_dreamers.push(item)
      }  
      this.$emit('dreamers-selected', sel_dreamers)

      if(sel_dreamers.length == this.dreamers.length){
        this.allSelected = true
      } else {
        this.allSelected = false
      }

    },
    async getData(){
      let data = {
        'user_id':  this.$store.getters['user/getUserData'].id,
        'group': null,
        'nee': null,
        'age_range': null,
        'searchword': '',
        'start_date':  new Date(this.startDate).getTime(),
        'end_date': new Date(this.endDate).getTime(),
        'token': this.$store.getters['user/getUserData'].sso,
      }
      /* INFO BASICA PARA LOS FILTROS */
      await this.$http.post(searchDreamers, data).then( response =>{
        // console.log(response.data, "--getDreamer-----------------")
        if(response.status == 200){
          this.groups = response.data.groups;
          this.nee = response.data.nee;
          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })

      /* DREAMERS */
      await this.$http.post(getDreamers, data).then( response =>{
        // console.log(response.data, "--getDreamer-----------------")
        if(response.status == 200){
          this.dreamers = response.data.dreamers;
          if(this.selected_dreamers.length == this.dreamers.length){
            this.allSelected = true
          } else {
            this.allSelected = false
          }
          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>