<template>
  <div>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
      <template v-else>
        <b-row class="mb-1">
          <b-col>
            <b-form-checkbox
              v-model="allSelected"
              @change="manageSelectAll()"
              inline
            >
              {{ $t('table.select_all')}}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="3"
            md="6"
            v-for="group in groups"
            v-bind:key="group.id"
          >

            <avatar-card 
              :item="group"
              :dropdown_options="[]"
              context="group"
              :isReport="true"
              :selected_items="selected_groups"
              @item-selected="itemSelected"

              />
        
          </b-col>
        </b-row>
      </template>
   
  </div>
</template>

<script>
import { getGroups } from '@/api/reports'
import AvatarCard from '@/views/sl-components/AvatarCard.vue'

import {
  BRow, BCol, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components:{
    BRow, BCol, BSpinner, BFormCheckbox,

    AvatarCard
  },
  props:{
    selected_groups:{
      type: Array,
      required: true,
      default() {
          return []
      }
    },
    endDate:{
      type: String,
      required: true
    },
    startDate:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      groups: [],

      allSelected: false,
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    manageSelectAll(){
      if(this.allSelected){
        let sel_groups =this.groups
        //let sel_groups =this.groups.map( i => i.id)
        this.$emit('groups-selected', sel_groups)
      } else {
        this.$emit('groups-selected', [])
      }
    },
    itemSelected(item){
      let sel_groups_ids = this.selected_groups.map(x => x.id)
      var index = sel_groups_ids.indexOf(item.id);
      let sel_groups = [...this.selected_groups]
      if (index !== -1) {
        sel_groups.splice(index, 1);
      } else {
        //sel_groups.push(item.id)
        sel_groups.push(item)
      }  
      this.$emit('groups-selected', sel_groups)

      if(sel_groups.length == this.groups.length){
        this.allSelected = true
      } else {
        this.allSelected = false
      }
  
    },
    async getData(){
     let data = {
      'user_id':  this.$store.getters['user/getUserData'].id,
      'start_date':  new Date(this.startDate).getTime(),
      'end_date': new Date(this.endDate).getTime(),
      'token': this.$store.getters['user/getUserData'].sso,
     } 
     // console.log(data, "tge")
      await this.$http.post(getGroups, data).then( response =>{
          if(response.status == 200){
            this.groups = response.data.groups;
            if(this.selected_groups.length == this.groups.length){
              this.allSelected = true
            } else {
              this.allSelected = false
            }
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>